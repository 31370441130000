export const filterNormalizeMixin = {
    methods: {
        filterNormalize (item, queryText, itemText) {
            const textOne = itemText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            const searchText = queryText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

            return textOne.indexOf(searchText) > -1
        },
    },
}
