export const dataTableMixin = {
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                total: 1,
                page: 1,
                itemsPerPage: 30
            },
            footerProps: {'items-per-page-options':[30, 50, 100]}
        }
    },
    methods: {
        handlerPaginatePromise(promise, varItems = 'items') {
            return promise.then(response => {
                this[varItems] = response.data.data.data;
                this.pagination.total = response.data.data.total;
                if (this.pagination.total < this.pagination.itemsPerPage) {
                    this.pagination.page = 1;
                }
            });
        }
    }
}
