<template>
    <div>
        <v-form ref="form">
            <v-row>
                <v-col cols="12" md="5">
                    <v-select
                        label="Pergunta"
                        outlined
                        v-model="filtro.id_pergunta_qualitativa"
                        dense
                        clearable
                        :items="perguntaSelect"
                        item-value="id_pergunta_qualitativa"
                        item-text="ds_pergunta_qualitativa"
                        @change="(event) => perguntaChange(event)"
                        :rules="validate('required', 'Pergunta')"
                    />
                </v-col>
                <v-col cols="12" md="5"
                    v-if="filtro.id_pergunta_qualitativa && perguntaSelect.find(p => p.id_pergunta_qualitativa === filtro.id_pergunta_qualitativa).tp_pergunta === 1"
                >
                    <v-select
                        label="Resposta"
                        outlined
                        v-model="filtro.id_opcao_resposta_pergunta_qualitativa"
                        dense
                        clearable
                        :items="opcaoRespostaSelect"
                        item-value="id_opcao_resposta_pergunta_qualitativa"
                        item-text="ds_opcao_resposta"
                    />
                </v-col>
                <v-col cols="12" md="5"
                       v-if="(filtro.id_pergunta_qualitativa && perguntaSelect.find(p => p.id_pergunta_qualitativa === filtro.id_pergunta_qualitativa).tp_pergunta !== 1) || filtro.id_pergunta_qualitativa === ''"
                >
                    <v-text-field
                        type="text"
                        label="Resposta"
                        outlined
                        dense
                        v-model="filtro.tx_resposta"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" align="left">
                    <v-btn
                        color="primary"
                        @click="addOpcao()"
                    >
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-row v-if="this.opcoes.length > 0">
            <v-col cols="12" md="12">
                <h3>Perguntas adicionadas ao filtro</h3>
                <v-data-table
                    :headers="opcaoHeaders"
                    :items="this.opcoes"
                    class="elevation-1 mb-0 mt-4"
                    hide-default-footer
                >

                <template v-slot:item.actions="{ item }">
                    <DeleteAction
                        @delete="deletar(item.id_pergunta_qualitativa)"
                    />
                </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { getAllPerguntaQualitativa } from "../../api/pergunta_qualitativa";
import { getOpcaoRespostaByPergunta } from "../../api/resposta_qualitativa";
import { validationMixin } from "../../mixins/validationMixin";
import DeleteAction from "../inputs/DeleteAction.vue";

export default {
    name: "OpcaoRelatorioQualitativo",
    mixins: [validationMixin],
    components: { DeleteAction },
    props: {
        disabled: {
            type: Boolean,
        },
        opcaoItens: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            perguntaSelect: [],
            opcaoRespostaSelect: [],
            opcoes: [],
            filtro: {
                id_pergunta_qualitativa: null,
                id_opcao_resposta_pergunta_qualitativa: null,
                tx_resposta: null,
            },
            opcaoHeaders: [
                { text: 'Pergunta', value: 'ds_pergunta', sortable: false },
                { text: 'Resposta', value: 'ds_opcao_resposta', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false, width: '120px' },
            ],
            visualizar: false,
            responder: false
        }
    },
    created() {
        getAllPerguntaQualitativa().then((response) => {
            this.perguntaSelect = response.data.data;
        });
    },
    methods: {
        perguntaChange(event) {
            if (this.filtro.id_pergunta_qualitativa != null) {
                getOpcaoRespostaByPergunta(event).then(response => this.opcaoRespostaSelect = response.data.data);
                this.filtro.id_orgao = null;
            } else {
                this.opcaoRespostaSelect = [];
            }
        },

        addOpcao() {
            if (this.$refs.form.validate()) {
                let arrOpcao = [];
                if ( this.opcoes ) {
                    arrOpcao = this.opcoes;
                }

                var idPergunta = this.filtro.id_pergunta_qualitativa;
                var dsPergunta = this.perguntaSelect.filter(function(item){ return item.id_pergunta_qualitativa == idPergunta });

                var idOpcao = this.filtro.id_opcao_resposta_pergunta_qualitativa;

                if ( idOpcao ) {
                    var dsOpcao = this.opcaoRespostaSelect.filter(function(item){ return item.id_opcao_resposta_pergunta_qualitativa == idOpcao });
                    arrOpcao.push(
                        {
                            "id_pergunta_qualitativa":this.filtro.id_pergunta_qualitativa,
                            "ds_pergunta":dsPergunta[0].ds_pergunta_qualitativa,
                            "id_opcao_resposta_pergunta_qualitativa": this.filtro.id_opcao_resposta_pergunta_qualitativa,
                            "ds_opcao_resposta":dsOpcao[0].ds_opcao_resposta
                        }
                    );
                } else {
                    arrOpcao.push(
                        {
                            "id_pergunta_qualitativa":this.filtro.id_pergunta_qualitativa,
                            "ds_pergunta":dsPergunta[0].ds_pergunta_qualitativa,
                        }
                    );
                }

                if (this.filtro.id_pergunta_qualitativa && this.perguntaSelect.find(p => p.id_pergunta_qualitativa === this.filtro.id_pergunta_qualitativa).tp_pergunta !== 1) {
                    arrOpcao[0]["tx_resposta"] = this.filtro.tx_resposta
                    arrOpcao[0]["ds_opcao_resposta"] = arrOpcao[0]["tx_resposta"];
                }

                this.opcoes = arrOpcao;
                this.$emit("opcaoRelatorioQualitativaEmit", this.opcoes);

                this.filtro.id_pergunta_qualitativa = null;
                this.filtro.id_opcao_resposta_pergunta_qualitativa = null;
                this.opcaoRespostaSelect = [];
            }
        },

        deletar(id_pergunta_qualitativa) {
            var arrOpcao = this.opcoes.filter(function(item){ return item.id_pergunta_qualitativa !== id_pergunta_qualitativa });
            this.opcoes = arrOpcao;
            this.$emit("opcaoRelatorioQualitativaEmit", this.opcoes);
        },
    },
}
</script>
