import api from './index';

const getQuestionariosRespondidosPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('questionario-respondido/questionario-respondido-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

const getAllPerguntas = () => api.get('pergunta/pergunta-all');

const getAnosQuestionarios = (filtrosAplicados = []) => api.get('questionario-respondido/questionario-respondido-obter-anos', {
    params: {
        filtros: filtrosAplicados,
    }
});

const extracaoRelatorioQuestionario = (filtrosAplicados = []) => api.get('questionario-respondido/extracaoRelatorioQuestionario', { params: {filtros: filtrosAplicados}, responseType: 'blob' } );

export {
    getQuestionariosRespondidosPaginate,
    getAllPerguntas,
    getAnosQuestionarios,
    extracaoRelatorioQuestionario
}
