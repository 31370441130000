<template>
    <v-navigation-drawer :value="showSideBar" @input="closeNagation($event)" id="navigation" app temporary absolute>
        <v-list nav dense class="pa-0">
            <v-list-item-group v-for="(route,menu) in routes" :key="menu" active-class="white--text text--accent-4">
                <v-list-group :value="false" v-if="route.submenu" v-bind:id="route.titulo">
                    <template v-slot:activator>
                        <v-list-item-title class="font-weight-light" color="#1351b4">{{
                                route.titulo
                            }}
                        </v-list-item-title>
                    </template>

                    <v-divider></v-divider>

                    <div class="grey lighten-4">
                        <v-list-item
                            link
                            v-for="(route,submenu) in route.submenu"
                            :key="submenu"
                            :to="route.pathsub"
                            v-bind:id="route.titulosub"
                        >
                            <v-list-item-title class="pl-3 font-weight-light">{{route.titulosub}}</v-list-item-title>
                        </v-list-item>
                    </div>

                    <v-divider></v-divider>
                </v-list-group>
                <v-list-item link :to="route.path" v-else v-bind:id="route.titulo">
                    <v-list-item-title class="font-weight-light" color="#1351b4">{{ route.titulo }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'Menu',
    methods: {
        closeNagation(value) {
            if (!value) {
                this.$store.commit('menu/closeSideBar')
            }
        }
    },
    computed: {
        routes() {
            var routes = [];
            let roles = this.$store.getters['auth/usuarioAutenticadoRoles'] ? this.$store.getters['auth/usuarioAutenticadoRoles'] : null;

            if (roles) {
                this.$router.options.routes.forEach(function (item, index, object) {
                    if (item.meta.menu.show && roles.some(r => item.meta.roles.indexOf(r) >= 0)) {
                        var route = {
                            'name': item.name,
                            'titulo': item.meta.menu.titulo,
                            'path': item.path
                        };

                        if (item.children) {
                            var routeSub = {};
                            Object.entries(item.children).forEach(function (chi, index) {
                                if (chi[1].submenu && roles.some(r => chi[1].meta.roles.indexOf(r) >= 0)) {
                                    routeSub[index] = {
                                        'titulosub': chi[1].meta.breadcrumb.titulo,
                                        'pathsub': chi[1].path
                                    }

                                    route.submenu = routeSub;
                                }
                            })
                        }

                        routes.push(route);
                    }
                });
            }

            return routes;
        },
        showSideBar() {
            return this.$store.getters['menu/g_sideBarOpen'];
        }
    },
}
</script>

<style scope>
#navigation .v-list-item-group .v-list-item {
    color: #1351b4 !important;
}

#navigation .v-list-item-group .v-list-item--active {
    background-color: #0c326f;
    color: #FFF !important;
}

#navigation .v-list--nav .v-list-item, .v-list--nav .v-list-item::before {
    border-radius: 0;
    min-height: 50px;
}

</style>
