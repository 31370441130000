import api from './index';

const getNotificacoes = () => api.get('notificacao', {showLoader: false});
const markAsReadNotificacao = (id) => api.put(`notificacao/${id}`);
const deleteNotificacao = (id) => api.delete(`notificacao/${id}`);
const deleteNotificacoes = () => api.delete(`notificacao`);

export {
    getNotificacoes,
    markAsReadNotificacao,
    deleteNotificacao,
    deleteNotificacoes,
}
