import api from './index';

const getRespostaAnexoDownload = (id_resposta_anexo) => api.get('resposta-anexo/' + id_resposta_anexo + '/download', {responseType: 'blob'})

const uploadRespostaAnexo = (id_resposta, dados) => api.post(`resposta-anexo/upload/${id_resposta}`, dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
});

const deleteRespostaAnexo = (id_resposta_anexo) => api.delete(`resposta-anexo/${id_resposta_anexo}`);

const getNomeRealArq = (id_resposta_anexo) => api.get(`resposta-anexo/nomeRealArq/${id_resposta_anexo}`);

export {    
    getRespostaAnexoDownload,
    uploadRespostaAnexo,
    deleteRespostaAnexo,
    getNomeRealArq
}